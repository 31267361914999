import React from "react"
import footerStyles from "./footer.module.scss"
import footerLogo from "../../images/footerLogo.png"
import emailImg from "../../images/13.png"
import fbImg from "../../images/9.png"
import igImg from "../../images/10.png"
import twitterImg from "../../images/8.png"
import { BrowserView, MobileView } from "react-device-detect"
export default function Footer() {
  return (
    <div>
      <BrowserView>
        <div className={footerStyles.container}>
          <img className={footerStyles.img} src={footerLogo} alt="footerLogo" />
          <p className={footerStyles.description}>
            {" "}
            UNIACCES 2019 Aviso de Privacidad Terminos y condiciones Aviso
            Legal.{" "}
          </p>
          <img
            className={footerStyles.socialMediaIcons}
            src={emailImg}
            alt="eamil"
          />
          <p className={footerStyles.contactanos}> Contactanos </p>
          <a
            href="https://www.facebook.com/uniaccesBC"
            style={{ height: "100px" }}
          >
            <img
              className={footerStyles.socialMediaIcons}
              src={fbImg}
              alt="fbimage"
            />
          </a>
          <img
            className={footerStyles.socialMediaIcons}
            src={twitterImg}
            alt="twitterimg"
          />
          <a
            href="https://www.facebook.com/uniaccesBC"
            style={{ height: "100px" }}
          >
            <img
              className={footerStyles.socialMediaIcons}
              src={igImg}
              alt="instaimage"
            />
          </a>
        </div>
      </BrowserView>
      <MobileView>
        <div className={footerStyles.container}>
          <img className={footerStyles.img} src={footerLogo} alt="footerLogo" />
          <div className={footerStyles.socialMediaContainer}>
            <img
              className={footerStyles.socialMediaIcons}
              src={emailImg}
              alt="eamil"
            />
            <p className={footerStyles.contactanos}> Contactanos </p>
            <a
              href="https://www.facebook.com/uniaccesBC"
              style={{ height: "70px" }}
            >
              <img
                className={footerStyles.socialMediaIcons}
                src={fbImg}
                alt="fbimage"
              />
            </a>
            <img
              className={footerStyles.socialMediaIcons}
              src={twitterImg}
              alt="twitterimg"
            />
            <a
              href="https://www.instagram.com/uniacces.curso"
              style={{ height: "70px" }}
            >
              <img
                className={footerStyles.socialMediaIcons}
                src={igImg}
                alt="instaimage"
              />
            </a>
          </div>
          <p className={footerStyles.description}>
            {" "}
            UNIACCES 2019 Aviso de Privacidad Terminos y condiciones Aviso
            Legal.{" "}
          </p>
        </div>
      </MobileView>
    </div>
  )
}
