import React, { useState, useRef, useEffect } from "react"
import logo from "../../images/1.png"
import headerStyles from "./mobileHeader.module.scss"
import { Link } from "gatsby"

export default function HeaderMobile(props) {
  const [uiColor, setUiColor] = useState("trasnparerent")
  const [show, setShow] = useState("none")
  const [url, setUrl] = useState("")
  const latestUrl = useRef(url)
  const prevUrl = usePrevious(url)

  function myFunction() {
    if (show === "block") {
      setShow("display")
    } else {
      setShow("block")
    }
  }

  function lookUrl() {
    setUrl(window.location.pathname)
    if (url !== prevUrl) {
      setShow("none")
      changeInitialUI()
    }
  }

  function changeInitialUI() {
    if (
      url === "/tips-para-estudiar" ||
      url === "/muro-de-admitidos" ||
      url === "/inscripciones" ||
      url === "/contacto" ||
      url.includes("/publicaciones/")
    ) {
      setUiColor("white")
    } else {
      setUiColor("transparent")
    }
  }

  function usePrevious(value) {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  useEffect(() => {
    // Used to look at the latest url and the set the color correctly
    latestUrl.current = url
    lookUrl()
  })

  return (
    <div
      className={
        uiColor === "white"
          ? headerStyles.Maincontainer
          : headerStyles.MaincontainerTransparent
      }
    >
      <div
        className={
          uiColor === "white"
            ? headerStyles.container
            : headerStyles.containerTransparent
        }
      >
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        />
        <a className={headerStyles.icon} onClick={() => myFunction()}>
          <div
            className={
              uiColor === "white"
                ? headerStyles.menuIcon
                : headerStyles.menuIconTransparent
            }
          >
            <i
              class="fa fa-bars"
              style={{ fontStretch: "expanded" , fontSize:'25pt'}}
              aria-hidden="true"
            ></i>
          </div>
        </a>
        <div className={headerStyles.topnavTransparent}>
          <div className={headerStyles.linksContainer}>
            <Link to="/" className={headerStyles.active}>
              <img src={logo} className={headerStyles.img} alt="logo" />
            </Link>
            <div
              className={
                show === "block"
                  ? headerStyles.topnavOpen
                  : headerStyles.topnavClosed
              }
              id="myLinks"
            >
              <Link className={headerStyles.myLinks} to="/">
                ¿Quiénes Somos?
              </Link>
              <Link className={headerStyles.myLinks} to="cursos-en-tu-ciudad">
                Cursos
              </Link>
              <Link className={headerStyles.myLinks} to="maestros">
                Maestros
              </Link>
              <Link className={headerStyles.myLinks} to="publicaciones">
                Publicaciones
              </Link>
              <Link className={headerStyles.myLinks} to="tips-para-estudiar">
                Recomendaciones
              </Link>
              <Link className={headerStyles.myLinks} to="muro-de-admitidos">
                Resultados
              </Link>
            </div>
            <div
              className={
                uiColor === "white"
                  ? headerStyles.whiteBackground
                  : headerStyles.whiteBackgroundTransparent
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}
