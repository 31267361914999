import React from "react"
import WebHeader from "./headerWeb"
import MobilHeader from "./mobileHeader"
import Styles from "./header.module.scss"

export default function Header() {
  return (
    <div>
      <div className={Styles.browserView}>
        <WebHeader />
      </div>
      <div className={Styles.mobileView}>
        <MobilHeader />
      </div>
    </div>
  )
}
