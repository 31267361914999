import React, { useState, useRef, useEffect } from "react"
import logo from "../../images/1.png"
import headerStyles from "./headerWeb.module.scss"
import { Link } from "gatsby"

export default function Header() {
  const [uiColor, setUiColor] = useState("white")
  const [url, setUrl] = useState("")
  const latestUrl = useRef(url)
  const prevUrl = usePrevious(url)

  function lookUrl() {
    setUrl(window.location.pathname)
    if (url !== prevUrl) {
      changeInitialUI()
    }
  }

  function changeInitialUI() {
    if (
      url === "/" ||
      url === "/tips-para-estudiar" ||
      url === "/muro-de-admitidos" ||
      url === "/inscripciones" ||
      url === "/contacto" ||
      url.includes("/publicaciones/") ||
      url.includes("/city/")
    ) {
      setUiColor("white")
    } else {
      setUiColor("transparent")
    }
  }

  function usePrevious(value) {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  useEffect(() => {
    latestUrl.current = url
    lookUrl()
  })

  return (
    <div
      className={
        uiColor === "white"
          ? headerStyles.container
          : headerStyles.containerTransparent
      }
    >
      <ul
        className={
          uiColor === "white" ? headerStyles.ul : headerStyles.ulTransparent
        }
      >
        <li>
          <img src={logo} className={headerStyles.img} alt="logo" />
        </li>
        <li>
          <Link
            className={
              uiColor === "white"
                ? headerStyles.text
                : headerStyles.textTransparent
            }
            class="active"
            to="/"
          >
            ¿Quiénes Somos?
          </Link>
        </li>
        <li>
          <Link
            className={
              uiColor === "white"
                ? headerStyles.text
                : headerStyles.textTransparent
            }
            to="cursos-en-tu-ciudad"
          >
            Cursos
          </Link>
        </li>
        <li>
          <Link
            className={
              uiColor === "white"
                ? headerStyles.text
                : headerStyles.textTransparent
            }
            to="maestros"
          >
            Maestros
          </Link>
        </li>
        <li>
          <Link
            className={
              uiColor === "white"
                ? headerStyles.text
                : headerStyles.textTransparent
            }
            to="publicaciones"
          >
            Publicaciones
          </Link>
        </li>
        <li>
          <Link
            className={
              uiColor === "white"
                ? headerStyles.text
                : headerStyles.textTransparent
            }
            to="tips-para-estudiar"
          >
            Recomendaciones
          </Link>
        </li>
        <li>
          <Link
            className={
              uiColor === "white"
                ? headerStyles.text
                : headerStyles.textTransparent
            }
            to="muro-de-admitidos"
          >
            Resultados
          </Link>
        </li>
      </ul>
    </div>
  )
}
